import { createPinia } from "pinia";
import { OnCreateAppSync } from "vike-vue/types";
import FormFieldContext from "../components/forms/form-field-context";
import { ApiClient } from "../shared/api-client";
import { MosparoApiClient, MosparoApiClientToken } from "../shared/api-client/mosparo";

export const onCreateApp: OnCreateAppSync = (pageContext) => {
    const apiClient = new ApiClient();
    const mosparoClient = new MosparoApiClient(apiClient);
    
    pageContext.app
        .use(createPinia())
        .provide(FormFieldContext.key, new FormFieldContext())
        .provide(ApiClient.token, apiClient)
        .provide(MosparoApiClientToken, mosparoClient);
};
