import { type InjectionKey } from "vue";
import ApiError from "../errors/api-error";
import AltchaData from "./altcha-data";
import { Payload } from "altcha-lib/types";
import { z, ZodType } from "zod";

export class ApiClient {

    // Static Configuration
    public static readonly token = Symbol('ApiClient') as InjectionKey<ApiClient>;

    /** Sends a request to the server API. */
    public async request(
        url: string,
        init?: RequestInit,
        altcha?: AltchaData
    ) {

        // Prepare the init data
        init ??= { };
        const headers = new Headers(init.headers);

        if (altcha) {
            const payload: Payload = {
                algorithm: altcha.challenge.algorithm,
                challenge: altcha.challenge.challenge,
                number: altcha.solution.number,
                salt: altcha.challenge.salt,
                signature: altcha.challenge.signature
            };

            headers.set('X-ALTCHA', btoa(JSON.stringify(payload)));
        }
        
        // Send the request
        init.headers = headers;
        const response = await fetch(url, init);

        // Check for errors
        if (response.status >= 400) {

            // Parse JSON error
            if (response.headers.get('Content-Type')?.startsWith('application/json')) {
                try {
                    const data = await response.json();

                    if (typeof data?.message === 'string' && data.message.trim() !== '') {
                        throw new ApiError(response.status, data.message, data.extra, data.serverStack);
                    }
                }
                catch (error) {
                    if (error instanceof ApiError) throw error;

                    console.error(`Failed to parse JSON error from ${url}`);
                    console.error(error);
                }
            }
            
            // Fall back to unknown error
            throw new ApiError(response.status, `An unknown error occurred (HTTP ${response.status})`);

        }

        // Read the body
        return response;

    }

    // JSON Helpers

    /** Sends an HTTP get request and reads the response content as JSON. */
    public async getJson<T = any>(
        url: string,
        init?: RequestInit,
        altcha?: AltchaData
    ): Promise<T> {
        init ??= { };
        init.method ??= 'GET';

        const response = await this.request(url, init, altcha);

        return await response.json();
    }

    // JSON Model Helpers

    /** Sends an HTTP get request and reads the response content as JSON encoded Zod model. */
    public async getModel<T extends ZodType>(
        url: string,
        model: T,
        init?: RequestInit,
        altcha?: AltchaData
    ): Promise<z.infer<T>> {
        init ??= { };
        init.method ??= 'GET';

        const response = await this.request(url, init, altcha);

        return await model.parseAsync(await response.json());
    }

    /** Sends an HTTP get request and reads the response content as JSON encoded Zod model. */
    public async postModel<T extends ZodType>(
        url: string,
        model: T,
        data: any,
        init?: RequestInit,
        altcha?: AltchaData
    ): Promise<z.infer<T>> {
        init ??= { };
        init.method ??= 'POST';

        const headers = new Headers(init.headers);
        headers.append('Content-Type', 'application/json');

        init.headers = headers;
        init.body = JSON.stringify(data);

        const response = await this.request(url, init, altcha);

        return await model.parseAsync(await response.json());
    }

}
