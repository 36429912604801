import type { OnPageTransitionEndAsync, PageContext } from 'vike/types';
import { useLoadingStore } from './loading-state';

export const onPageTransitionEnd: OnPageTransitionEndAsync = async (
  _: PageContext
): ReturnType<OnPageTransitionEndAsync> => {
  const loadingState = useLoadingStore();
  loadingState.active = false;

  if(globalThis.fbq) {
    globalThis.fbq('track', 'PageView');
  }
};