
/** Throw an ApiError to generate a JSON encoded response with a specific error code. */
export default class ApiError extends Error {

    constructor(
        public readonly statusCode: number,
        message: string,
        public readonly extra?: any,
        public readonly serverStack?: string
    ) {
        super(message);
        this.name = 'ApiError';
    }

}
