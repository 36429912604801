import { z } from "zod";

export const MosparoSubmitTokenModel = z.object({
    
    
    submitToken: z.string(),

    messages: z.object({
        label: z.string(),
        accessibilityCheckingData: z.string(),
        accessibilityDataValid: z.string(),
        errorGotNoToken: z.string(),
        errorInternalError: z.string(),
        errorNoSubmitTokenAvailable: z.string(),
        errorSpamDetected: z.string(),
        errorLockedOut: z.string(),
        errorDelay: z.string(),
        hpLeaveEmpty: z.string()
    })

});

export type MosparoSubmitToken = z.infer<typeof MosparoSubmitTokenModel>;

export const MosparoFormDataModel = z.object({
    fields: z.array(z.object({
        name: z.string(),
        value: z.string(),
        fieldPath: z.string()
    })),
    ignoredFields: z.array(z.string())
});

export type MosparoFormData = z.infer<typeof MosparoFormDataModel>;

export const MosparoValidationTokenModel = z.object({

    valid: z.boolean(),

    validationToken: z.string().or(z.null())

});

export type MosparoValidationToken = z.infer<typeof MosparoValidationTokenModel>;

export const MosparoSubmissionContextModel = z.object({
    data: z.any(),
    submitToken: z.string(),
    validationToken: z.string(),
});

export type MosparoSubmissionContext = z.infer<typeof MosparoSubmissionContextModel>;
