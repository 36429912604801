<script lang="ts" setup>
import { ref, watch, onErrorCaptured, computed } from 'vue';
import { useLoadingStore } from './loading-state';
import ApiError from '../shared/errors/api-error';
import { ModalContainer } from '@fss/vue-modal';

    // Watch for loading changes
    const loadingState = useLoadingStore();
    let showLoading = ref<boolean>(loadingState.active);
    let currentTimeout: any;

    watch(loadingState.$state, loading => {
        if (currentTimeout != null) {
            clearTimeout(currentTimeout);
            currentTimeout = undefined;
        }

        if (showLoading.value === true && loading.active === false) {
            showLoading.value = false;
            return;
        }
        
        currentTimeout = setTimeout(() => {
            showLoading.value = loading.active;
        }, 50);
    });

    // Watch for errors
    const caughtError = ref<Error>();

    onErrorCaptured(error => {
        caughtError.value = error;
    });

    const serverStack = computed(() => {
        if (caughtError.value instanceof ApiError) {
            return caughtError.value.serverStack;
        }

        return undefined;
    });

    const hasServerStack = computed(() => serverStack.value != null && serverStack.value.trim() !== '');

</script>

<template>

    <ModalContainer>

        <!-- Loading -->
        <Transition>
            <div class="fixed top-0 left-0 right-0 bottom-0 bg-white flex justify-center items-center p-4"
                v-if="showLoading"
            >
                <div class="text-2xl text-slate-700 font-bold">Loading</div>
            </div>
        </Transition>

        <!-- Error -->
        <main
            v-if="caughtError != null"
            class="m-4"
        >
            <div class="text-xl m-4 font-bold text-red-600">An Unhandled Error Occurred</div>
            <div class="whitespace-pre-wrap m-4 text-red-600">{{ caughtError.message }}</div>

            <template v-if="hasServerStack">

                <div class="whitespace-pre-wrap m-4 border rounded p-2 text-xs"
                >{{ serverStack }}</div>

                <div class="text-xs m-4">Client Error Details</div>

            </template>

            <div
                v-if="caughtError.stack"
                class="whitespace-pre-wrap m-4 border rounded p-2 text-xs"
            >{{ caughtError.stack }}</div>

        </main>

        <!-- Main Content -->
        <slot v-else/>

    </ModalContainer>

</template>

<style scoped>

    .v-enter-active, .v-leave-active {
        transition: opacity 0.5s ease;
    }

    .v-enter-from, .v-leave-to {
        opacity: 0;
    }

</style>
