import { inject, InjectionKey } from "vue";
import { ApiClient } from ".";
import { MosparoFormData, MosparoSubmissionContext, MosparoSubmitToken, MosparoSubmitTokenModel, MosparoValidationToken, MosparoValidationTokenModel } from "../../server/mosparo/models";

export class MosparoApiClient {

    constructor(
        private readonly api: ApiClient
    ) { }

    public async requestSubmitTokenForPage(pageTitle: string): Promise<MosparoSubmitToken> {
        const submitToken = await this.api.postModel('/mosparo/request-submit-token', MosparoSubmitTokenModel, {
            pageTitle
        });

        return submitToken;
    }

    public async checkFormData(submitToken: string, formData: MosparoFormData): Promise<MosparoValidationToken> {
        const validationToken = await this.api.postModel('/mosparo/check-form-data', MosparoValidationTokenModel, {
            submitToken, formData
        });

        return validationToken;
    }

    public loadFormData(form: HTMLElement, options: {
        ignoreTypes?: string[]
    } | undefined = undefined): MosparoFormData {
        const ignoreFields = new Set<string>();

        const data: MosparoFormData = {
            fields: [ ],
            ignoredFields: [ ]
        };

        options ??= { };
        options.ignoreTypes ??= [ 'password', 'file', 'hidden', 'checkbox', 'radio', 'submit', 'reset' ];

        for (const input of form.querySelectorAll('input')) {
            if (options.ignoreTypes.includes(input.type)) {
                ignoreFields.add(input.name)
            }
            else {
                data.fields.push({
                    name: input.name,
                    value: typeof input.value === 'string'
                        ? input.value
                        : String(input.value),
                    fieldPath: `input[${input.type}].${input.name}`
                });
            }
        }
        
        for (const input of form.querySelectorAll('select')) {
            if (options.ignoreTypes.includes(input.type)) {
                data.ignoredFields.push(input.name)
            }
            else {
                data.fields.push({
                    name: input.name,
                    value: typeof input.value === 'string'
                        ? input.value
                        : String(input.value),
                    fieldPath: `select.${input.name}`
                });
            }
        }
        
        for (const input of form.querySelectorAll('textarea')) {
            if (options.ignoreTypes.includes(input.type)) {
                data.ignoredFields.push(input.name)
            }
            else {
                data.fields.push({
                    name: input.name,
                    value: typeof input.value === 'string'
                        ? input.value
                        : String(input.value),
                    fieldPath: `textarea.${input.name}`
                });
            }
        }

        data.ignoredFields = [...ignoreFields];
        return data;
    }

    public prepareSubmissionContext(formData: MosparoFormData, submitToken: MosparoSubmitToken, validationToken: MosparoValidationToken): MosparoSubmissionContext {
        if (!validationToken.valid || validationToken.validationToken == null) {
            throw new Error('Unable to create Mosparo submission context with invalid token');
        }
        
        const data: Record<string, any> = { };

        for (const field of formData.fields) {
            data[field.name] = field.value;
        }

        return {
            data,
            submitToken: submitToken.submitToken,
            validationToken: validationToken.validationToken
        };
    }

}

export const MosparoApiClientToken = Symbol('MosparoApiClient') as InjectionKey<MosparoApiClient>;

export function useMosparoApiClient(): MosparoApiClient {
    const client =  inject(MosparoApiClientToken);

    if (client === undefined) {
        throw new Error('No Mosparo API client registered');
    }

    return client;
}
